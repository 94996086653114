import styled from 'styled-components';

export const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-gap: 0;
`;
export const InputBox = styled.div`
  padding: 2.5rem;
  text-align: center;
  border-top: 1px solid #7b8c95;
  border-right: 1px solid #7b8c95;
  input[type='checkbox'] {
    transform: scale(2);
  }
`;
export const Label = styled.label`
  font-size: 1.5em;
  padding: 2rem;
  margin: 0;
  font-family: 'Source Sans Pro';
  border-top: 1px solid #7b8c95;
`;

export const ScrollingDiv = styled.div`
  width: 100%;
  overflow: auto;
`;
