import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  BACKGROUND_COLORS,
  TEXT_COLORS,
  BACKGROUND_COLOR_ERROR,
  COLOR_ERROR,
} from '../utils/constants';
import { ButtonGroup } from './styles/Modal';

const Drawer = styled.div`
  position: relative;
  width: content-min;
  z-index: 1000;
`;

const MessageContainer = styled.div`
  background-color: ${props =>
    BACKGROUND_COLORS[props.messageType] || BACKGROUND_COLOR_ERROR};
  color: ${props => TEXT_COLORS[props.messageType] || COLOR_ERROR};
  border-radius: 0.5em;
  font-family: 'Source Sans Pro', sans serif;
  font-style: italic;
`;

const Button = styled.button`
  border-radius: 0.5em;
  border-color: rgb(255, 255, 255, 0.3);
  background-color: rgb(0, 0, 0, 0.25);
  color: rgb(0, 0, 0, 0.67);
  margin: 0.5em;
  height: 3em;
  &:hover {
    color: rgb(0, 0, 0, 1);
    cursor: pointer;
  }
  font-family: 'Source Sans Pro', sans serif;
  font-style: italic;
`;

const Text = styled.p`
  text-align: center;
  margin: 1em;
`;

const Confirm = ({ message, messageType, dismiss, onConfirm, onCancel }) => (
  <Drawer>
    {message && (
      <MessageContainer messageType={messageType}>
        <Text>{message}</Text>
        <ButtonGroup>
          <Button
            onClick={() => {
              dismiss();
              onConfirm();
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              dismiss();
              onCancel();
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </MessageContainer>
    )}
  </Drawer>
);

Confirm.propTypes = {
  dismiss: PropTypes.func.isRequired,
  message: PropTypes.string,
  messageType: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

Confirm.defaultProps = {
  message: '',
  messageType: 'red',
};

export default Confirm;
