import styled from 'styled-components';
import FormBanner from './FormBanner';
import LargeInput from './LargeInput';
import LargeLabel from './LargeLabel';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';
import DeleteButton from './DeleteButton';
import { ButtonLink } from './Link';

export const InputGroup = styled.div`
  display: grid;
  justify-content: space-evenly;
  margin-bottom: 10px;
  width: 50%;
`;
export const ModalBanner = styled(FormBanner)`
  margin: 0;
  padding: 0;
  min-height: 100%;
`;
export const Image = styled.img`
  max-width: 300px;

  @media (max-width: 768px) {
    max-width: 150px;
  }
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 15px;
`;
export const ResponsiveInput = styled(LargeInput)`
  @media (max-width: 768px) {
    height: initial;
  }
`;
export const ResponsiveLabel = styled(LargeLabel)`
  @media (max-width: 768px) {
    fontsize: 1em;
  }
`;
export const ResponsiveSubmitButton = styled(SubmitButton)`
  @media (max-width: 768px) {
    font-size: initial;
    padding: 5px;
    margin: 5px;
  }
`;
export const ResponsiveCancelButton = styled(CancelButton)`
  @media (max-width: 768px) {
    font-size: initial;
    padding: 5px;
    margin: 5px;
  }
`;
export const ResponsiveDeleteButton = styled(DeleteButton)`
  @media (max-width: 768px) {
    font-size: initial;
    padding: 5px;
    margin: 5px;
  }
`;
export const H1 = styled.h1`
  font-family: 'Source Sans Pro';
  font-size: 48px;
  margin-top: 0;
  margin-bottom: 0;
  font-style: italic;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;
export const LabelButton = styled(ButtonLink)`
  font-style: italic;
  color: blue;
  border-bottom: 0;
`;

export const MenuToggle = styled.button`
  border: none;
  background: transparent;
  width: max-content;
  cursor: pointer;
  padding: 0;
  transform: ${props => (props.value ? 'rotate(-90deg)' : '')};

  img {
    width: 20px;
  }
`;
