import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { onEnter } from '../../utils/helpers';
import {
  ButtonGroup,
  H1,
  Image,
  InputGroup,
  ModalBanner,
  ResponsiveCancelButton,
  ResponsiveInput,
  ResponsiveLabel,
  ResponsiveSubmitButton,
} from '../styles/Modal';
import useGlobal from '../../store';
import Select from '../Select';

const CarModal = ({ car, onClose, storeFronts }) => {
  const [, actions] = useGlobal();
  const [licence, setLicence] = useState('');
  const [name, setName] = useState('');
  const [storeFront, setStoreFront] = useState({});
  const { editCar } = actions;

  useEffect(
    () => {
      if (car.id) {
        setLicence(car.plate_number);
        setName(car.name);
        setStoreFront(storeFronts[car.storefront] || {});
      }
    },
    [car]
  );

  const edit = async () => {
    onClose();
    await editCar(car.id, {
      plate_number: licence,
      name,
      storefront: storeFront.id || null,
    });
  };

  return (
    <Modal
      isOpen={car.id}
      onRequestClose={onClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <ModalBanner>
        <H1>Car Details</H1>
        <Image src={car.image} alt="car" />
        <InputGroup>
          <ResponsiveLabel htmlFor="car-name">Name:</ResponsiveLabel>
          <ResponsiveInput
            id="car-name"
            onChange={e => setName(e.target.value)}
            onKeyUp={e => onEnter(e, edit)}
            value={name}
          />
        </InputGroup>
        <InputGroup>
          <ResponsiveLabel htmlFor="car-licence">
            License Plate:
          </ResponsiveLabel>
          <ResponsiveInput
            id="car-licence"
            onChange={e => setLicence(e.target.value)}
            onKeyUp={e => onEnter(e, edit)}
            value={licence}
          />
        </InputGroup>
        <InputGroup>
          <ResponsiveLabel htmlFor="car-licence">Store Front:</ResponsiveLabel>
          <Select
            setter={setStoreFront}
            current={storeFront}
            defaultOptionText="Purchased From"
            options={storeFronts}
          />
        </InputGroup>
        <ButtonGroup>
          <ResponsiveSubmitButton onClick={edit}>Update</ResponsiveSubmitButton>
          <ResponsiveCancelButton onClick={onClose}>
            Cancel
          </ResponsiveCancelButton>
        </ButtonGroup>
      </ModalBanner>
    </Modal>
  );
};

CarModal.propTypes = {
  car: PropTypes.shape({
    id: PropTypes.number,
    configuration: PropTypes.Object,
    image_url: PropTypes.string,
    name: PropTypes.string,
    plate_number: PropTypes.string,
    storefront: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  storeFronts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
};

export default CarModal;
