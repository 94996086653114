import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parseUrl } from 'query-string';
import { navigate } from 'gatsby-link';
import useGlobal from '../store';
import Page from '../components/Page';
import Container from '../components/styles/Container';
import * as constants from '../utils/constants';
import { padding } from '../theme';
import DriverModal from '../components/driver/DriverModal';
import DriverMenu from '../components/driver/DriverMenu';
import SortMenu from '../components/garage/SortMenu';
import {
  GarageHeading,
  HeadingGroup,
  HeadingLink,
} from '../components/styles/GaragePage';
import CarsList from '../components/garage/CarsList';
import { loginRedirectEffect } from '../utils/helpers';

const GaragePage = ({ location }) => {
  const [state, actions] = useGlobal();
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [modalEditMode, setModalEditMode] = useState(false);
  const { token, driver, loadingDriver, loadingToken } = state;
  const { addToGarage, clearDriver, downloadLicense } = actions;

  // redirect if there is no token or no driver
  useEffect(
    () => {
      if (!driver.id && !loadingDriver)
        navigate(`/switch-driver${location.search}`);
    },
    [driver, loadingDriver, location]
  );

  loginRedirectEffect(token, loadingToken, location);

  // add car from configurator to garage
  useEffect(
    () => {
      if (location.search && driver.id) {
        const qs = parseUrl(location.search).query;
        const { c: qsCar } = qs;
        if (qsCar) {
          const c = JSON.parse(window.atob(qsCar));
          c.driver = driver.id;
          addToGarage(c);
          navigate('/garage');
        }
      }
    },
    [location, addToGarage, driver]
  );

  const Heading = driver.name
    ? `${driver.name.toUpperCase()}'S GARAGE`
    : 'GARAGE';

  return (
    <Page>
      <Container py={padding}>
        <HeadingGroup>
          <GarageHeading>{Heading}</GarageHeading>
          <DriverMenu
            showSwitch={() => {
              clearDriver();
              navigate('switch-driver/');
            }}
            showEdit={async () => {
              await setModalEditMode(true);
              await setShowDriverModal(true);
            }}
            showAdd={async () => {
              await setModalEditMode(false);
              await setShowDriverModal(true);
            }}
            downloadLicense={async () => {
              await downloadLicense(driver.id);
            }}
            licenseUnlocked={driver.is_license_unlocked}
          />
        </HeadingGroup>
        <HeadingLink>
          <a href={constants.CONFIGURATOR_URL}>
            Click Here to Design Your Own Car with the
            <em> Modarri Online Car Designer!</em>
          </a>
        </HeadingLink>
        <DriverModal
          onClose={() => setShowDriverModal(false)}
          open={showDriverModal}
          isEdit={modalEditMode}
        />
        <SortMenu />
        <CarsList />
      </Container>
    </Page>
  );
};

GaragePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default GaragePage;
