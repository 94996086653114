import styled from 'styled-components';
import { Link } from 'rebass';

// import { colors } from '../../theme';

export default styled(Link)`
  color: #666;
  font-family: 'Open Sans', sans-serif;
  &:hover {
    color: #7b8c95;
    cursor: pointer;
  }
`;
export const SelfHidingLink = styled.a`
  display: ${props => (props.isCustom ? 'inline' : 'none')};
  margin-right: 20px;
  font-family: 'Open Sans';
  color: #384548;
  font-size: 12px;
  border-bottom: 1px solid #384548;
  cursor: pointer;
`;

export const LinkButton = styled(SelfHidingLink)`
  background-color: #369af9;
  color: white;
  font-family: 'Source Sans Pro';
  font-weight: bold;
  padding: 5px 20px;
  height: 100%;
  border-radius: 13.333px;
  border: 0;
  margin-right: 0;
  font-size: 1em;
`;

export const ButtonLink = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  font-family: 'Open Sans';
  margin-right: 20px;
  color: #384548;
  border-bottom: 1px solid #384548;
  font-size: 12px;
`;
