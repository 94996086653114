import React, { useEffect, useState } from 'react';
import useGlobal from '../../store';
import * as constants from '../../utils/constants';
import { onEnter } from '../../utils/helpers';
import { ButtonLink, LinkButton, SelfHidingLink } from '../styles/Link';
import { SubmitButtonSmall } from '../styles/SubmitButton';
import { BorderedInput } from '../styles/Input';
import {
  ButtonHolder,
  CarContainer,
  CarDetailsContainer,
  CarHeadingContainer,
  CarName,
  CarPlate,
  GarageContainer,
  ImageContainer,
  LinkContainer,
  NewCarForm,
} from '../styles/GaragePage';
import CarModal from './CarModal';
import SwitchDriverModal from '../driver/SwitchDriverModal';
import Select from '../Select';

const blankCarImage = require('../../images/mystery_car.jpg');

const CarsList = () => {
  const [state, actions] = useGlobal();
  const [license, setLicense] = useState('');
  const [name, setName] = useState('');
  const [storeFront, setStoreFront] = useState({});
  const [modalCar, setModalCar] = useState({});
  const [driverModalCar, setDriverModalCar] = useState({});
  const { garage, driver, storeFronts } = state;
  const { deleteCar, getGarage, registerNewCar } = actions;

  const register = async () => {
    await registerNewCar({
      plate_number: license,
      name,
      storefront: storeFront.id,
    });
    setName('');
    setLicense('');
    setStoreFront({});
  };

  const getConfiguratorUrl = (car, addToCart = false) => {
    const payload = JSON.stringify({
      name: car.name,
      imageUrl: car.image_url,
      id: car.id,
      storefront: car.storefront,
      addToCart,
    });
    return (
      (!car.plate_number || car.plate_number.slice(-2) === 'CC') &&
      `${constants.CONFIGURATOR_URL}?payload=${btoa(payload)}&${
        car.config_querystring
      }`
    );
  };

  useEffect(
    () => {
      if (driver.id) {
        getGarage();
      }
    },
    [driver]
  );

  return (
    <GarageContainer>
      <CarModal
        onClose={() => setModalCar({})}
        car={modalCar}
        storeFronts={storeFronts}
      />
      <SwitchDriverModal
        onClose={() => setDriverModalCar({})}
        car={driverModalCar}
      />
      {garage.map(car => (
        <CarContainer key={car.id}>
          <ImageContainer background={car.image} />
          <CarDetailsContainer>
            <CarHeadingContainer>
              <CarName>{car.name || 'Name'}</CarName>
              <LinkButton
                href={getConfiguratorUrl(car, true)}
                isCustom={car.is_from_configurator}
              >
                BUY
              </LinkButton>
            </CarHeadingContainer>
            <CarPlate>{car.license_plate || 'Licence Plate'}</CarPlate>
            <LinkContainer>
              <ButtonLink onClick={() => deleteCar(car.id)}>Delete</ButtonLink>
              <SelfHidingLink
                href={getConfiguratorUrl(car)}
                isCustom={car.is_from_configurator}
              >
                Customize
              </SelfHidingLink>
              <ButtonLink onClick={() => setModalCar(car)}>Edit</ButtonLink>
              <ButtonLink onClick={() => setDriverModalCar(car)}>
                Move
              </ButtonLink>
            </LinkContainer>
          </CarDetailsContainer>
        </CarContainer>
      ))}
      <div>
        <ImageContainer background={`${blankCarImage}`}>
          <CarName>Register</CarName>
          <small>
            <em>All fields are required</em>
          </small>
          <NewCarForm>
            <BorderedInput
              id="license"
              onChange={e => setLicense(e.target.value)}
              onKeyUp={e => onEnter(e, register)}
              placeholder="Enter license"
              value={license}
              width="150px"
            />
            <BorderedInput
              id="name"
              onChange={e => setName(e.target.value)}
              onKeyUp={e => onEnter(e, register)}
              placeholder="Enter name"
              value={name}
              width="150px"
            />
            <Select
              setter={setStoreFront}
              current={storeFront}
              defaultOptionText="Purchased From"
              options={storeFronts}
            />
          </NewCarForm>
        </ImageContainer>
        <ButtonHolder>
          <SubmitButtonSmall
            disabled={!(license && name && storeFront.id)}
            onClick={register}
          >
            Add New Car
          </SubmitButtonSmall>
        </ButtonHolder>
      </div>
    </GarageContainer>
  );
};

CarsList.propTypes = {};

export default CarsList;
