import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import useGlobal from '../../store';
import { getRandomInt, onEnter } from '../../utils/helpers';
import {
  ButtonGroup,
  H1,
  InputGroup,
  LabelButton,
  MenuToggle,
  ModalBanner,
  ResponsiveCancelButton,
  ResponsiveDeleteButton,
  ResponsiveInput,
  ResponsiveLabel,
  ResponsiveSubmitButton,
} from '../styles/Modal';
import Confirm from '../Confirm';
import * as constants from '../../utils/constants';
import AvatarSelection from './AvatarSelection';
import arrow from '../../images/arrow.png';
import DriversLicense from './DriverLicense';

const DriverModal = ({ onClose, open, isEdit }) => {
  const [state, actions] = useGlobal();
  const { driver, avatars } = state;
  const { addDriver, editDriver, deleteDriver } = actions;
  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState({});
  const [message, setMessage] = useState('');
  const [toggle, setToggle] = useState(true);

  useEffect(
    () => {
      if (isEdit) {
        setName(driver.name);
        setAvatar(avatars[driver.avatar] || {});
      } else setName('');
    },
    [driver, isEdit]
  );

  const edit = async () => {
    onClose();
    await editDriver(driver.id, { name, avatar: avatar.id || null });
  };

  const add = async () => {
    onClose();
    await addDriver({ name });
  };

  const generateRandomName = () => {
    const num = getRandomInt(0, constants.DRIVERS_NAMES.length);
    setName(constants.DRIVERS_NAMES[num]);
  };

  const hide = !driver.is_license_unlocked || !isEdit;

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <ModalBanner>
        <H1>{isEdit ? 'Edit Driver' : 'Create Driver'}</H1>
        <InputGroup>
          <ResponsiveLabel htmlFor="driver-name">
            Name:&nbsp;
            <LabelButton onClick={generateRandomName}>
              Generate Name
            </LabelButton>
          </ResponsiveLabel>
          <ResponsiveInput
            id="driver-name"
            onChange={e => setName(e.target.value)}
            onKeyUp={e => onEnter(e, isEdit ? edit : add)}
            value={name}
          />
        </InputGroup>
        {!hide && <DriversLicense driver={driver} avatar={avatar} />}
        <ResponsiveLabel hidden={hide}>
          Avatar{' '}
          <MenuToggle value={toggle} onClick={() => setToggle(!toggle)}>
            <img alt="arrow" src={arrow} />
          </MenuToggle>
        </ResponsiveLabel>
        <AvatarSelection
          onSelect={setAvatar}
          avatars={avatars}
          avatar={avatar}
          hidden={hide || toggle}
        />
        <ButtonGroup>
          <ResponsiveSubmitButton onClick={isEdit ? edit : add}>
            {isEdit ? 'Update' : 'Create'}
          </ResponsiveSubmitButton>
          {isEdit && (
            <ResponsiveDeleteButton
              onClick={() => {
                setMessage(constants.MESSAGE_CONFIRM_DELETE_DRIVER);
              }}
            >
              Delete
            </ResponsiveDeleteButton>
          )}
          <ResponsiveCancelButton onClick={onClose}>
            Cancel
          </ResponsiveCancelButton>
        </ButtonGroup>
        <Confirm
          message={message}
          messageType={constants.MESSAGE_TYPE_ERROR}
          dismiss={() => setMessage('')}
          onConfirm={() => {
            setMessage('');
            deleteDriver(driver.id);
            onClose();
          }}
          onCancel={() => setMessage('')}
        />
      </ModalBanner>
    </Modal>
  );
};

DriverModal.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DriverModal;
