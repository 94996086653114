import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import licenseImage from '../../images/license.png';

const LicenseWrapper = styled.div`
  min-width: 425px;
  margin: 30px 15px 0 15px;

  @media (max-width: 768px) {
    min-width: 75vw;
  }
`;

const License = styled.div`
  position: relative;
  color: #000;
  font-size: 16px;
  letter-spacing: normal;
  background: url(${licenseImage}) no-repeat;
  background-size: contain;
  padding-top: 13%;
  overflow: hidden;

  &:before {
    content: '';
    padding-top: 53.2%;
    float: left;
  }
`;

const Photo = styled.div`
  position: absolute;
  margin: 4.45% 0 0 4.45%;
  width: 27.78%;

  img {
    border: 0.14em solid #1d75bd;
    border-radius: 10px;
    display: block;
    width: 125px;

    @media (max-width: 768px) {
      width: 20vw;
    }
  }
`;

const Value = styled.div`
  font-family: 'Eurostile Bold', 'Open Sans Bold', sans-serif;
  font-weight: 900;
  word-wrap: break-word;
  text-transform: uppercase;
`;

const Details = styled.div`
  margin-left: 31.11%;
  font-family: 'Eurostile', 'Open Sans', Helvetica, Arial, sans-serif;
  line-height: 1.05;
  padding: 4.45% 4.45% 0;

  & > * {
    margin-bottom: 4.5%;
    overflow: hidden;
  }

  @media (max-width: 530px) {
    font-size: calc(3.8vw - 3px);
  }
`;

const Issued = styled.div`
  float: left;
`;

const SignatureGroup = styled.div`
  margin: 0;
`;

const Type = styled.div`
  position: absolute;

  .label {
    font-size: 87.5%;
  }
`;

const Signature = styled.div`
  margin: 16% 3% 0 20%;

  .label {
    margin: 3px 0 0 5px;
    font-size: 87.5%;
  }
`;

const Dates = styled.div`
  .label {
    font-size: 87.5%;
  }
  .value {
    letter-spacing: 0;
  }
`;

const Line = styled.div`
  border-top: 1px solid;
`;

const DriversLicense = ({ driver, avatar }) => (
  <LicenseWrapper>
    <License>
      <Photo>
        <img alt="driver avatar" src={avatar.image_url} />
      </Photo>
      <Details>
        <div>
          <div className="label">LICENSE ID</div>
          <Value className="value">{driver.license_id}</Value>
        </div>
        <div>
          <div className="label">FULL NAME</div>
          <Value className="value">{driver.name}</Value>
        </div>
        <div>
          <Dates>
            <Issued>
              <div className="label">ISSUE DATE</div>
              <Value className="value">{driver.issue_date}</Value>
            </Issued>
          </Dates>
        </div>
        <SignatureGroup>
          <Type>
            <div className="label">TYPE</div>
            <Value className="value">PRO</Value>
          </Type>
          <Signature>
            <Line />
            <div className="label">SIGNATURE</div>
          </Signature>
        </SignatureGroup>
      </Details>
    </License>
  </LicenseWrapper>
);

DriversLicense.propTypes = {
  driver: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    license_id: PropTypes.string,
    issue_date: PropTypes.string,
    avatar: PropTypes.number,
    is_license_unlocked: PropTypes.bool,
  }).isRequired,
  avatar: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image_url: PropTypes.string,
  }).isRequired,
};

export default DriversLicense;
