import PropTypes from 'prop-types';
import React from 'react';
import { Select } from './styles/Select';

const SelectList = ({ current, options, setter, defaultOptionText }) => {
  const isArray = Array.isArray(options);
  const list = isArray ? options : Object.values(options);
  return (
    <Select onChange={e => setter(options[e.currentTarget.value] || {})}>
      <option>{defaultOptionText}</option>
      {list.map((o, index) => (
        <option value={isArray ? index : o.id} selected={current.id === o.id}>
          {o.name}
        </option>
      ))}
    </Select>
  );
};

SelectList.propTypes = {
  current: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  options: PropTypes.oneOfType(PropTypes.array, PropTypes.object).isRequired,
  setter: PropTypes.func.isRequired,
  defaultOptionText: PropTypes.string,
};

SelectList.defaultProps = {
  defaultOptionText: '- - -',
};

export default SelectList;
