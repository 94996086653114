import React, { useState } from 'react';
import {
  DriverMenuBody,
  DriverMenuButton,
  Icon,
  MenuToggle,
  SortMenu,
  SortMenuHeading,
} from '../styles/GaragePage';
import arrow from '../../images/arrow.png';
import useGlobal from '../../store';

const Menu = () => {
  const [, actions] = useGlobal();
  const [showSortMenu, setShowSortMenu] = useState(false);
  const [filterState, setFilterState] = useState('');
  const { getGarage } = actions;

  const filterGarage = (e, filter) => {
    setFilterState(e.currentTarget.value);
    getGarage(filter);
  };

  return (
    <SortMenu>
      <MenuToggle
        value={showSortMenu}
        onClick={() => setShowSortMenu(!showSortMenu)}
      >
        <SortMenuHeading>
          <Icon alt="arrow icon" src={arrow} />{' '}
          <h2>Sort {filterState && `(${filterState})`}</h2>
        </SortMenuHeading>
      </MenuToggle>
      <DriverMenuBody hidden={!showSortMenu}>
        <DriverMenuButton
          onClick={e => filterGarage(e, 'name')}
          value="Name Ascending"
        >
          <input
            type="checkbox"
            disabled
            checked={filterState === 'Name Ascending'}
          />
          <span>Name Ascending</span>
        </DriverMenuButton>
        <DriverMenuButton
          onClick={e => filterGarage(e, '-name')}
          value="Name Descending"
        >
          <input
            type="checkbox"
            disabled
            checked={filterState === 'Name Descending'}
          />
          <span>Name Descending</span>
        </DriverMenuButton>
        <DriverMenuButton
          onClick={e => filterGarage(e, '-created')}
          value="Newest"
        >
          <input type="checkbox" disabled checked={filterState === 'Newest'} />
          <span>Newest</span>
        </DriverMenuButton>
        <DriverMenuButton
          onClick={e => filterGarage(e, 'created')}
          value="Oldest"
        >
          <input type="checkbox" disabled checked={filterState === 'Oldest'} />
          <span>Oldest</span>
        </DriverMenuButton>
      </DriverMenuBody>
    </SortMenu>
  );
};

Menu.propTypes = {};

export default Menu;
