import styled from 'styled-components';
import { Input } from '@rebass/forms';
import React from 'react';

export default props => (
  <Input
    {...props}
    sx={{
      backgroundColor: 'white',
      borderRadius: '5px',
      color: 'gray',
      border: 0,
    }}
  />
);

export const BorderedInput = styled.input`
  display: block;
  padding: 8px;
  border: 1px solid grey;
  border-radius: 5px;
  width: 150px;
  font-family: Open Sans;
  margin-top: 10px;
`;
