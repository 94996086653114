import styled from 'styled-components';
import Heading from './Heading';

export const ImageContainer = styled.div`
  color: #384548;
  height: 185px;
  background: url(${props => props.background}) center no-repeat;
  background-size: 100%;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 250px;
  input {
    margin-left: 15%;
    margin-top: 5px;
    background-color: rgba(255, 255, 255, 0.66);
    ::placeholder {
      color: black;
    }
  }
  h2 {
    margin-bottom: 5px;
    overflow: visible;
  }
  small {
    font-family: Open Sans;
    color: black;
  }
`;
export const CarDetailsContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 10px 2px 30px;
  color: #384548;
  max-width: 250px;
`;
export const CarHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const CarName = styled.h2`
  font-size: 20px;
  font-weight: bold;
  font-family: 'Source Sans Pro';
  letter-spacing: 0.02em;
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 5px;
  &:hover {
    overflow: visible;
  }
`;
export const CarPlate = styled.div`
  justify-self: center;
  font-weight: bold;
  font-family: 'Source Sans Pro';
  letter-spacing: 0.02em;
  margin-bottom: 0px;
`;
export const GarageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-template-rows: 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 50px;
  padding-top: 40px;
`;
export const LinkContainer = styled.div`
  margin: 0 0 0.25em 0;
`;
export const CarContainer = styled.div`
  align-self: start;
`;
export const HeadingLink = styled.h2`
  color: #2064a0;
  font-family: 'Source Sans Pro';
  font-weight: normal;
  a:visited {
    color: #2064a0;
  }
`;
export const GarageHeading = styled(Heading)`
  color: #384548;
  font-style: normal;
`;
export const ButtonHolder = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  button {
    margin-top: 10px;
    grid-column-start: 1;
    place-self: start end;
`;
export const NewCarForm = styled.div`
  justify-self: center;

  select {
    display: block;
    padding: 8px;
    color: black;
    border: 1px solid grey;
    border-radius: 5px;
    width: 170px;
    font-family: Open Sans;
    margin-top: 5px;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-align: start;
    -webkit-appearance: textfield;
    font: 400 13.3333px Arial;
    margin-left: 15%;
    background-color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
  }
`;
export const DriverMenuBody = styled.div`
  display: ${props => (props.hidden ? 'none' : 'grid')};
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  grid-template-columns: 1fr;
  border-bottom: 2px solid #7b8c95;
  border-left: 2px solid #7b8c95;
  border-right: 2px solid #7b8c95;
  button:nth-child(odd) {
    background-color: #e8e8e8;
  }
`;
export const DriverMenuButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  background: white;
  padding: 15px 5px;
  font-family: 'Source Sans Pro';
  font-size: 12pt;
  color: #384548;
`;
export const MenuToggle = styled.button`
  border: none;
  background: transparent;
  width: max-content;
  cursor: pointer;
  border-top: ${props => (props.value ? '2px solid #7b8c95' : 'none')};
  border-left: ${props => (props.value ? '2px solid #7b8c95' : 'none')};
  border-right: ${props => (props.value ? '2px solid #7b8c95' : 'none')};
  padding: 0;
  text-align: left;
`;
export const MenuHeading = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  white-space: nowrap;
  color: #384548;
  h2 {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    margin-right: 1em;
  }
`;
export const SortMenuHeading = styled.div`
  white-space: nowrap;
  padding: 10px 0;
  img {
    display: inline;
  }
  h2 {
    display: inline;
    font-family: 'Source Sans Pro';
    font-weight: normal;
  }
`;
export const HeadingGroup = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  @media (max-width: 780px) {
    grid-template-columns: 1fr;
  }
`;
export const DriverMenu = styled.div`
  justify-self: center;
  position: relative;
  @media (max-width: 780px) {
    justify-self: start;
  }
`;
export const Icon = styled.img`
  max-width: 25px;
  margin: 0 1em;
`;
export const SortMenu = styled.div`
  position: relative;
  div {
    width: 20em;
  }
`;
