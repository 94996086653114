import styled from 'styled-components';

export default styled.button`
  font-size: 22px;
  font-weight: 700;
  padding: 12px 20px;
  color: #fff;
  background-color: #6c757d;
  border: 1px solid #6c757d;
  border-radius: 4px;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed !Important;
  }
`;
