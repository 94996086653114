import React from 'react';
import PropTypes from 'prop-types';
import { InputBox, Label, Row, ScrollingDiv } from '../styles/SwitchDriverPage';

const ListComponent = ({ clickHandler, labelFormatter, elements, current }) => (
  <ScrollingDiv>
    {Object.values(elements).map(e => (
      <Row>
        <InputBox>
          <input
            id={`${e.name}-btn`}
            type="checkbox"
            name="driver"
            value={e.id}
            onClick={evt => clickHandler(evt, e)}
            checked={current.id === e.id}
          />
        </InputBox>
        <Label htmlFor={`${e.name}-btn`}>
          {labelFormatter ? labelFormatter(e.name) : e.name}
        </Label>
      </Row>
    ))}
  </ScrollingDiv>
);

ListComponent.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  labelFormatter: PropTypes.func,
  elements: PropTypes.shape({
    [PropTypes.number]: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
  current: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

ListComponent.defaultProps = {
  labelFormatter: null,
  current: {},
};

export default ListComponent;
