import React, { useState } from 'react';
import PropTypes from 'prop-types';
import menu from '../../images/hamburger_menu.png';
import edit from '../../images/edit.png';
import add from '../../images/add.png';
import arrow from '../../images/arrow.png';
import switchUser from '../../images/switch_user.png';
import {
  DriverMenu,
  DriverMenuBody,
  DriverMenuButton,
  Icon,
  MenuHeading,
  MenuToggle,
} from '../styles/GaragePage';

const Menu = ({
  licenseUnlocked,
  downloadLicense,
  showAdd,
  showEdit,
  showSwitch,
}) => {
  const [showDriverMenu, setShowDriverMenu] = useState(false);

  return (
    <DriverMenu>
      <MenuToggle
        value={showDriverMenu}
        onClick={() => setShowDriverMenu(!showDriverMenu)}
      >
        <MenuHeading>
          <Icon alt="menu icon" src={menu} /> <h2>Driver Options</h2>
        </MenuHeading>
      </MenuToggle>
      <DriverMenuBody hidden={!showDriverMenu}>
        <DriverMenuButton onClick={showEdit}>
          <Icon alt="edit icon" src={edit} />
          <span>Edit Driver</span>
        </DriverMenuButton>
        <DriverMenuButton onClick={showSwitch}>
          <Icon alt="switch icon" src={switchUser} />
          <span>Switch Driver</span>
        </DriverMenuButton>
        <DriverMenuButton onClick={showAdd}>
          <Icon alt="add icon" src={add} />
          <span>New Driver</span>
        </DriverMenuButton>
        {licenseUnlocked && (
          <DriverMenuButton onClick={downloadLicense}>
            <Icon alt="download icon" src={arrow} />
            <span>Download License</span>
          </DriverMenuButton>
        )}
      </DriverMenuBody>
    </DriverMenu>
  );
};

Menu.propTypes = {
  licenseUnlocked: PropTypes.bool,
  downloadLicense: PropTypes.func.isRequired,
  showAdd: PropTypes.func.isRequired,
  showEdit: PropTypes.func.isRequired,
  showSwitch: PropTypes.func.isRequired,
};

Menu.defaultProps = {
  licenseUnlocked: false,
};

export default Menu;
