import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Selection = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  justify-content: center;
`;

const Img = styled.img`
  max-width: 75px;
  border: ${props =>
    props['data-current'] ? '2px solid #2064a0' : '2px solid rgb(123,140,149)'};
`;

const Btn = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.5;
  }
`;

const AvatarSelection = ({ onSelect, avatars, avatar, hidden }) => (
  <Selection>
    {Object.values(avatars).map(a => (
      <Btn
        key={a.id}
        value={a.id}
        onClick={e => onSelect(avatars[e.currentTarget.value] || {})}
        hidden={hidden}
      >
        <Img
          data-current={a.id === avatar.id}
          src={a.image_url}
          alt="driver avatar"
        />
      </Btn>
    ))}
  </Selection>
);

AvatarSelection.propTypes = {
  avatar: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image_url: PropTypes.string,
  }),
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      image_url: PropTypes.string,
    })
  ).isRequired,
  hidden: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

AvatarSelection.defaultProps = {
  avatar: {
    id: 0,
    name: '',
    image_url: '',
  },
  hidden: false,
};

export default AvatarSelection;
