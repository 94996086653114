import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { stringFormat } from '../../utils/helpers';

import {
  ButtonGroup,
  H1,
  ModalBanner,
  ResponsiveCancelButton,
  ResponsiveSubmitButton,
} from '../styles/Modal';
import useGlobal from '../../store';
import List from '../garage/List';
import * as constants from '../../utils/constants';

const SwitchDriverModal = ({ car, onClose }) => {
  const [state, actions] = useGlobal();
  const [_driver, setDriver] = useState('');
  const { driver, drivers } = state;
  const { editCar, setMessage } = actions;

  useEffect(
    () => {
      setDriver(driver);
    },
    [driver]
  );

  const edit = async () => {
    onClose();
    const updated = await editCar(car.id, { driver: _driver.id });
    if (updated && updated.id) {
      const message = stringFormat(
        constants.MESSAGE_CAR_MOVE_SUCCESS,
        updated.name,
        _driver.name
      );
      setMessage(message, constants.MESSAGE_TYPE_SUCCESS);
    }
  };

  return (
    <Modal
      isOpen={car.id}
      onRequestClose={onClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <ModalBanner>
        <H1>Select Garage</H1>
        <List
          clickHandler={(e, d) => setDriver(d)}
          elements={drivers}
          current={_driver}
          labelFormatter={name => `${name}'s Garage`}
        />
        <ButtonGroup>
          <ResponsiveSubmitButton onClick={edit}>Move</ResponsiveSubmitButton>
          <ResponsiveCancelButton onClick={onClose}>
            Cancel
          </ResponsiveCancelButton>
        </ButtonGroup>
      </ModalBanner>
    </Modal>
  );
};

SwitchDriverModal.propTypes = {
  car: PropTypes.shape({
    id: PropTypes.number,
    configuration: PropTypes.Object,
    image_url: PropTypes.string,
    name: PropTypes.string,
    plate_number: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SwitchDriverModal;
