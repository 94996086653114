import styled from 'styled-components';

export const Select = styled.select`
  box-shadow: none;
  border-top: 0px;
  border-left: 1px;
  border-right: 1px;
  border-radius: 5px !important;
  border-color: #f0f0f0cf;
  font-size: 1.2em;
  background-color: #f0f0f0cf;
  color: #0067ee;
  outline: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-bottom: -5px;
  margin-right: 3px;
  text-align: center;
  text-align-last: center;
  border-width: medium;
  padding-left: 5px;
  padding-right: 5px;
`;
